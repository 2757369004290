import { getParent, types } from 'mobx-state-tree';
import { jwtDecode } from 'jwt-decode';
import requests from 'lib/requests';
import { EmailInputStore, ErrorStore, InputStore, NoDisplayError, PasswordInputStore } from 'components/forms/Input';
import { AuthenticationMixin } from 'pages/Auth/LoginS';
var InvitedUserS = types
    .model('InvitedUserS', {
    token: types.string,
    firstName: types.optional(InputStore, function () {
        return InputStore.create({ label: 'First name', error: NoDisplayError.create({}) });
    }),
    lastName: types.optional(InputStore, function () {
        return InputStore.create({ label: 'Last name', error: NoDisplayError.create({}) });
    }),
    email: types.optional(EmailInputStore, function () {
        return EmailInputStore.create({ error: NoDisplayError.create({}), disabled: true });
    }),
    password: types.optional(PasswordInputStore, function () { return PasswordInputStore.create({ error: ErrorStore.create({}) }); }),
    error: types.optional(ErrorStore, function () { return ErrorStore.create({}); }),
    creating: false,
})
    .views(function (self) { return ({
    get filled() {
        return self.firstName.isDone() && self.lastName.isDone() && self.password.isDone();
    },
    get parent() {
        return getParent(self);
    },
}); })
    .actions(function (self) { return ({
    setup: function (tokenPayload) {
        self.email.setValue(tokenPayload.email);
        if (tokenPayload.first_name) {
            self.firstName.setValue(tokenPayload.first_name);
        }
        if (tokenPayload.last_name) {
            self.lastName.setValue(tokenPayload.last_name);
        }
    },
    setCreating: function (value) {
        self.firstName.setDisabled(value);
        self.lastName.setDisabled(value);
        self.password.setDisabled(value);
        self.creating = value;
    },
    fetchManagementToken: function () {
        requests.POST({
            url: '/m/api/v1/auth/tokens/mgmt-token',
            // authToken: self.token,
            body: {
                email: self.email.value,
                password: self.password.value,
            },
            onFailure: self.parent.processError,
            onSuccess: function (response, body) {
                self.parent.onSuccessLogin(body.data.token);
            },
        });
    },
    processInvitation: function () {
        var _this = this;
        this.setCreating(true);
        requests.POST({
            url: "/m/api/v1/users",
            authToken: self.token,
            body: {
                email: self.email.value,
                first_name: self.firstName.value,
                last_name: self.lastName.value,
                password: self.password.value,
            },
            onFailure: function (response, errors) {
                self.error.set(errors.join('\n'));
                _this.setCreating(false);
            },
            onSuccess: function () { return setTimeout(_this.fetchManagementToken, 500); },
        });
    },
}); });
var OAuth2CallbackForm = AuthenticationMixin.named('OAuth2CallbackForm')
    .props({
    provider: types.maybeNull(types.string),
    code: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    notRegisteredYet: false,
    completed: false,
    invitation: types.maybeNull(InvitedUserS),
})
    .actions(function (self) { return ({
    markAsNotRegistered: function () {
        self.notRegisteredYet = true;
    },
    onSuccessCallbackResponse: function (response, responseBody) {
        self.setRedirectURL(
        // @ts-ignore: AuthResponseBody is a generic type that does not describe start_url
        responseBody.data.start_url);
        var tokenPayload = jwtDecode(responseBody.data.token);
        if (tokenPayload.typ === 'u-invitation' || tokenPayload.typ === 'u-oauth2-invitation') {
            self.invitation = InvitedUserS.create({ token: responseBody.data.token });
            self.invitation.setup(tokenPayload);
        }
        else {
            self.onSuccessLogin(responseBody.data.token);
        }
    },
    login: function () {
        requests.GET({
            url: "/m/api/v1/auth/oauth2/".concat(self.provider, "/callback"),
            query: {
                code: self.code,
                state: self.state,
            },
            onFailure: self.processError,
            onSuccess: this.onSuccessCallbackResponse,
            onFinish: function () { return self.setProcessing(false); },
        });
    },
}); });
export default OAuth2CallbackForm;
